import React from 'react'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from 'gatsby'

import { FooterDataContent } from './models'

import Logo from '@images/logo-white.svg'
import facebook from '@images/icons/facebook.svg'
import instagram from '@images/icons/instagram.svg'
import linkedin from '@images/icons/linkedin.svg'
import warning from '@images/icons/warning.svg'

const Footer = styled(({ className }) => {
  const contact = FooterDataContent()[0]
  const social = FooterDataContent()[1]
  const copyright = FooterDataContent()[2]
  const footer_text = FooterDataContent()[3]

  return (
    <div className={`${className}`}>
      <footer>
        <Container>
          <Row>
            <Col xs="12" md="12" className="pr-md-0">
              <div className="logo">
                <img src={Logo} alt="logo" />
              </div>
            </Col>
            <Col xs="12" md="12">
              <div className="footer-contact">
                <span className="h2">{contact.title}</span>
                <div className="p">
                  <div>
                    <span className="font-weight-bold">Phone:</span>{' '}
                    <a href={`tel:${contact.phone.link}`} className="phone">
                      {contact.phone.text}
                    </a>
                  </div>
                  <div>
                    <span className="font-weight-bold">Mail:</span>{' '}
                    <a href={`mailto:${contact.email}`} className="email">
                      {contact.email}
                    </a>
                  </div>
                </div>
                <p>{contact.address}</p>
              </div>
            </Col>
            {/* <Col xs="12" md="3">
              <span className="h2">{social.title}</span>
              <ul>
                {social.facebook !== '' && (
                  <li>
                    <Link to={social.facebook}>
                      <img src={facebook} alt="facebook"/>
                      <span>Facebook</span>
                    </Link>
                  </li>
                )}
                {social.instagram !== '' && (
                  <li>
                    <Link to={social.instagram}>
                      <img src={instagram} alt="instagram"/>
                      <span>Instagram</span>
                    </Link>
                  </li>
                )}
                {social.linkedin !== '' && (
                  <li>
                    <Link to={social.linkedin}>
                      <img src={linkedin} alt="linkedin"/>
                      <span>Linked In</span>
                    </Link>
                  </li>
                )}
              </ul>
            </Col> */}
            <Col xs="12">
              <div className="copyright">Copyright © {(new Date().getFullYear())}, {copyright}</div>
            </Col>
          </Row>
        </Container>
      </footer>
      <div className="footerText">
        <Container>
          <Row className="align-items-center">
            <Col sm="2" xl="1" className="d-none d-sm-block text-right">
              <img src={warning} alt="warning" className="warning" />
            </Col>
            <Col xs="12" sm="10" xl="11" className="svg-b">
              <p>{footer_text}</p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
})`
  footer {
    background: #231e60;
    text-align: center;
    padding: 40px 0;

    @media (min-width: 1200px) {
      padding-top: 60px;
    }

    .logo {
      margin-bottom: 0;
    }

    .h2 {
      color: #ffffff;
      font-family: Titillium Web !important;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      text-transform: uppercase;
      margin-top: 30px;
      margin-bottom: 20px;

      @media (min-width: 768px) {
        margin-bottom: 10px;
      }

      @media (min-width: 1200px) {
        display: inline-block;
        margin: 0 20px 0 0;
      }
    }

    .footer-contact {
      text-align: center;

      @media (min-width: 1200px) {
        margin-top: 32px;
      }
    }

    p, .p {
      color: #ffffff;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;

      a {
        color: inherit;

        &.phone{
          @media (min-width: 768px) {
            margin-right: 20px;
          }
        }

        &.email{
          @media (min-width: 1200px) {
            margin-right: 20px;
          }
        }
      }

      div{
        display: block;
        margin-bottom: 10px;

        @media (min-width: 768px) {
          display: inline-block;
        }
      }

      @media (min-width: 768px) {
        margin-bottom: 20px;
      }

      @media (min-width: 1200px) {
        display: inline-block;
      }
    }

    .p{
      @media (min-width: 768px) {
        margin-bottom: 0;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 55px;
      margin-bottom: 40px;

      @media (min-width: 768px) {
        display: block;
        padding: 0 0;
        margin-bottom: 0;
      }

      li {
        font-size: 16px;
        flex-basis: 0;
        line-height: 24px;
        max-width: 100%;

        img{
          height: 40px;
          width: 40px;

          @media (min-width: 1200px) {
            height: 30px;
            width: 30px;
          }
        }

        span {
          display: none;

          @media (min-width: 768px) {
            color: #ffffff;
            display: inline-block;
            margin-left: 16px;
          }
        }

        @media (min-width: 768px) {
          display: block;
          text-align: left;
          margin-bottom: 15px;
        }

        &:marker {
          display: none;
        }
      }
    }

    .copyright {
      color: #f4f7f6;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.01em;
      opacity: 0.5;
      margin-top: 30px;

      @media (min-width: 768px) {
        margin-top: 20px;
      }
    }
  }

  .footerText {
    padding: 20px 0;

    @media (min-width: 1200px) {
      padding: 40px 0;
    }

    p {
      color: #747474;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.01em;
      margin: 0;

      @media (min-width: 768px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    
    .warning{
      marging-right: 20px;
    }

    .svg-b {
      @media (min-width: 768px) {
        border-left: 1px solid #747474;
      }
    }
  }
`

export default Footer
