import React, { useContext } from 'react'
import styled from '@emotion/styled'

import ThemeContext from '@contexts/themeContext'

const Wrapper = styled(({ children, className }) => {
  const { activeMenu } = useContext(ThemeContext)

  return (
    <div
      className={`${className} ${
        activeMenu ? 'active' : ''
      } position-fixed navigation-content-mobile`}
    >
      {children}
    </div>
  )
})`
  bottom: 0;
  display: none;
  height: 100%;
  left: 0;
  overflow-y: auto;
  right: 0;
  z-index: 1010;
  animation: animatetop 0.4s;

  @keyframes animatetop {
    from {
      top: -100%;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  &.active {
    display: block;
  }
`

export default Wrapper
