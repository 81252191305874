import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

// components
import Header from './Header'
import CannabisHeader from './CannabisHeader'
import Footer from './Footer'
const Layout = styled(({ children, className, page }) => {
  const appendJobberScript = () => {
    const element = document.createElement('script')
    element.setAttribute(
      'src',
      'https://d3ey4dbjkt2f6s.cloudfront.net/assets/static_link/work_request_embed_dialog_snippet.js'
    )
    element.setAttribute('clienthub_id', '05909048-1d4f-49b4-b5a2-0774cca2aedb')
    element.setAttribute(
      'form_url',
      'https://clienthub.getjobber.com/client_hubs/05909048-1d4f-49b4-b5a2-0774cca2aedb/public/work_request/embedded_dialog_work_request_form'
    )
    document.body.appendChild(element)
  }
  useEffect(() => {
    appendJobberScript()
  }, [])
  return (
    <div className={className} id="main-layout">
      {page == 'cannabis' ? <CannabisHeader /> : <Header />}
      <main>{children}</main>
      <Footer />
    </div>
  )
})`
  margin: 0 auto;
  max-width: 1800px;
  overflow: hidden;
`

Layout.propTypes = {
  page: PropTypes.string
}

Layout.defaultProps = {
  data: {}
}
export default Layout
