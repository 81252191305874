import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { object } from 'prop-types'

import ThemeContext from '@contexts/themeContext'

const NavigationMobileBottom = styled(({ data, className }) => {
  const { activeMenu, toggleMenu, activePopup, togglePopup } = useContext(
    ThemeContext
  )

  return (
    <div className={`${className}`}>
      <div className="navigation-bottom-title">{data.title}</div>
      <div className="navigation-bottom-buttons">
        <button
          onClick={() => {
            toggleMenu(!activeMenu)
            togglePopup(!activePopup)
          }}
        >
          {data.cta.readmore}
        </button>
        <button
          onClick={() => {
            toggleMenu(!activeMenu)
            document.getElementsByClassName('embed-button')[0].click()
          }}
        >
          {data.cta.gethelp}
        </button>
      </div>
    </div>
  )
})`
  background-color: #fff;
  padding: 37px 38px 36px 30px;

  @media (min-width: 768px) {
    padding: 80px 64px;
  }

  .navigation-bottom-title {
    color: #141414;
    font-family: Titillium Web;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;

    @media (min-width: 768px) {
      text-align: left;
      margin-bottom: 40px;
    }
  }

  .navigation-bottom-buttons {
    button {
      align-items: flex-start;
      background: transparent;
      border: 2px solid #63a536;
      border-radius: 4px;
      display: flex;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      flex-direction: row;
      justify-content: center;
      padding: 16px 48px;
      text-transform: uppercase;
      transition: 0.25s;
      width: 100%;

      @media (min-width: 768px) {
        display: inline-block;
        margin-right: 20px;
        padding: 14px 48px;
        width: unset;
      }

      &:first-of-type {
        background: #63a536;
        color: #fff;
        margin-bottom: 15px;

        &:hover,
        &:focus {
          border-color: #231e60;
          box-shadow: inset 0 4.25em 0 0 #231e60;
          color: #fff;
          text-decoration: none;
        }
      }

      &:last-child {
        border: 2px solid #63a536;
        color: #63a536;

        &:hover,
        &:focus {
          border-color: #231e60;
          color: #231e60;
          text-decoration: none;
        }
      }
    }
  }
`

NavigationMobileBottom.propTypes = {
  data: object.isRequired
}

NavigationMobileBottom.defaultProps = {
  data: {}
}

export default NavigationMobileBottom
