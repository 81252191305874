import { useStaticQuery, graphql } from 'gatsby'

export const HeaderDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allHeaderDataJson } = useStaticQuery(
    graphql`
      query queryHeaderDataContent {
        allHeaderDataJson {
          edges {
            node {
              topbar {
                text
                email
                phone {
                  link
                  text
                }
              }
              navbar {
                navtop {
                  navigation {
                    title
                    slug
                  }
                  help
                  phone
                  email
                }
                navbottom {
                  title
                  cta {
                    readmore
                    gethelp
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return [
    allHeaderDataJson.edges[0].node.topbar,
    allHeaderDataJson.edges[0].node.navbar
  ]
}
