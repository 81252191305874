import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { object } from 'prop-types'
import scrollTo from 'gatsby-plugin-smoothscroll'

// components
import MainIcon from '../../../MainIcon'

import ThemeContext from '@contexts/themeContext'

import email from '@images/icons/email-green.svg'
import phone from '@images/icons/phone-green.svg'

const NavigationMobileTop = styled(({ data, className }) => {
  const { activeMenu, toggleMenu } = useContext(ThemeContext)

  return (
    <div className={`${className}`}>
      <div className="navigation-close-button">
        <MainIcon />
      </div>
      <div className="navigation-title">MENU</div>
      <div className="navigation-items">
        <ul className="list-unstyled m-0 pl-0">
          {data.navigation.map((item, index) => {
            return (
              <li key={index}>
                <button
                  onClick={() => {
                    scrollTo(`#` + item.slug)
                    toggleMenu(!activeMenu)
                  }}
                >
                  {item.title}
                </button>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="navigation-contact">
        <span className="h3">CONTACT US</span>
        <ul>
          <li>
            <a href={`mailto:${data.email}`}>
              <img src={email} alt="email"/>
              <span>Email</span>
            </a>
          </li>
          <li>
            <a href={`tel:${data.phone}`}>
              <img src={phone} alt="phone"/>
              <span>Phone</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
})`
  background-color: #231e60;
  padding: 34px 30px 79px;

  @media (min-width: 768px) {
    padding: 40px 64px 103px;
  }

  .navigation-close-button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;

    @media (min-width: 768px) {
      margin-bottom: 28px;
    }
  }

  .navigation-title {
    color: #63a536;
    font-family: Changa One;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 34px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 60px;

    @media (min-width: 768px) {
      font-size: 48px;
      line-height: 51px;
    }
  }

  .navigation-items {
    margin-bottom: 60px;

    ul {
      li {
        display: block;
        margin-bottom: 30px;

        a,
        button {
          background: transparent;
          border: none;
          color: #fff;
          font-family: 'Titillium Web';
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 28px;
          padding: 0;
          text-transform: uppercase;
          transition: 0.25s;

          &:hover,
          &:focus {
            color: #63a536;
            text-decoration: none;
          }
        }
      }
    }
  }

  .navigation-contact {
    .h3 {
      color: #ffffff;
      font-family: 'Titillium Web' !important;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 28px;
      text-transform: uppercase;
      margin-bottom: 20px;

      @media (min-width: 768px) {
        margin-bottom: 30px;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0;
      margin: 0;

      @media (min-width: 768px) {
        justify-content: start;
      }

      li {
        list-style-type: none;
        width: calc(50% - 10px);

        @media (min-width: 768px) {
          margin-right: 20px;
          width: 120px;
        }

        a {
          align-items: center;
          background-color: #fff;
          border-radius: 2px;
          color: #63a536;
          display: flex;
          flex-flow: column;
          justify-content: center;
          height: 65px;
          transition: 0.25s;

          span {
            font-family: 'Titillium Web';
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            margin-top: 5px;
          }

          img {
            fill: #fff;
            path {
              transition: 0.25s;
            }
          }

          &:hover,
          &:focus {
            box-shadow: inset 0 -5em 0 0 #63a536;
            color: #fff;
            text-decoration: none;

            img path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
`

NavigationMobileTop.propTypes = {
  data: object.isRequired
}

NavigationMobileTop.defaultProps = {
  data: {}
}

export default NavigationMobileTop
