import { useStaticQuery, graphql } from 'gatsby'

export const FooterDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allFooterDataJson } = useStaticQuery(
    graphql`
      query queryFooterDataContent {
        allFooterDataJson {
          edges {
            node {
              contact {
                title
                address
                phone {
                  link
                  text
                }
                email
              }
              social {
                title
                facebook
                instagram
                linkedin
              }
              copyright
              footer_text
            }
          }
        }
      }
    `
  )
  return [
    allFooterDataJson.edges[0].node.contact,
    allFooterDataJson.edges[0].node.social,
    allFooterDataJson.edges[0].node.copyright,
    allFooterDataJson.edges[0].node.footer_text
  ]
}
