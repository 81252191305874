import React from 'react'
import styled from '@emotion/styled'
import { object } from 'prop-types'

// components
import Wrapper from './components/_Wrapper'
import NavigationTop from './components/NavigationTop'
import NavigationBottom from './components/NavigationBottom'

const NavigationMobile = styled(({ data, className }) => {
  return (
    <div className={`${className}`}>
      <Wrapper>
        <NavigationTop data={data.navtop} />
        <NavigationBottom data={data.navbottom} />
      </Wrapper>
    </div>
  )
})``

NavigationMobile.propTypes = {
  data: object.isRequired
}

NavigationMobile.defaultProps = {
  data: {}
}

export default NavigationMobile
