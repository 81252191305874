import { createContext } from 'react'

const ThemeContext = createContext({
  activeMenu: false,
  activeGalleryPopup: false,
  selectedImage: 0,
  activePopup: false,
  activeTabs: 0,
  toggleMenu: () => {},
  togglePopup: () => {},
  togglePopupGallery: () => {}
})

export default ThemeContext
