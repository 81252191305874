import React, { useState } from 'react'
import { node } from 'prop-types'

import ThemeContext from '@contexts/themeContext'

const ThemeProvider = ({ children }) => {

  const togglePopup = () => {
    setOpen((prevState) => {
      return {
        ...prevState,
        activePopup: !prevState.activePopup
      }
    })
  }

  const togglePopupGallery = (first, second) => {
    setOpen((prevState) => {
      return {
        ...prevState,
        activeGalleryPopup: !prevState.activeGalleryPopup,
        selectedImage: second
      }
    })
  }

  const toggleTab = (first) => {
    setOpen((prevState) => {
      return {
        ...prevState,
        activeTab: first
      }
    })
  }

  const toggleMenu = () => {
    setOpen((prevState) => {
      return {
        ...prevState,
        activeMenu: !prevState.activeMenu
      }
    })
  }

  const loadingState = {
    activeMenu: false,
    activeGalleryPopup: false,
    selectedImage: 0,
    activePopup: false,
    activeTab: 0,
    toggleMenu,
    togglePopupGallery,
    togglePopup,
    toggleTab
  }

  const [open, setOpen] = useState(loadingState)

  return (
    <ThemeContext.Provider value={open}>
      {children}
    </ThemeContext.Provider>
  )
}

ThemeProvider.propTypes = {
  children: node
}

ThemeProvider.defaultProps = {
  children: null
}

export default ThemeProvider
