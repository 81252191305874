import React from 'react'
import styled from '@emotion/styled'
import { object } from 'prop-types'
import scrollTo from 'gatsby-plugin-smoothscroll'

const NavigationDesktop = styled(({ data, className }) => {
  return (
    <div className={`${className} d-none d-xl-block navigation-items`}>
      <ul>
        {data.navigation.map((item, index) => {
          return (
            <li key={index}>
              <button onClick={() => scrollTo(`#` + item.slug)}>
                {item.title}
              </button>
            </li>
          )
        })}
        <li className="get-help">
          <button
            onClick={() => {
              document.getElementsByClassName('embed-button')[0].click()
            }}
          >
            {data.help}
          </button>
        </li>
      </ul>
    </div>
  )
})`
  ul {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0;

    li {
      list-style-type: none;
      margin-right: 40px;

      &:nth-last-of-type(2) {
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
        margin-left: 40px;
      }

      a,
      button {
        background: transparent;
        border: none;
        color: #231e60;
        font-family: Titillium Web;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        padding: 18px 0;
        text-transform: uppercase;

        &:hover {
          color: #63a536;
        }
      }

      &.get-help {
        display: none;
        opacity: 0;
        visible: hidden;
        width: 0;

        button {
          border: 2px solid #231e60;
          border-radius: 4px;
          color: #231e60;
          display: block;
          font-family: Titillium Web;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 28px;
          text-transform: uppercase;
          padding: 14px 47px;

          &:hover {
            border-color: #63a536;
            color: #63a536;
          }
        }
      }
    }
  }
`

NavigationDesktop.propTypes = {
  data: object.isRequired
}

NavigationDesktop.defaultProps = {
  data: {}
}

export default NavigationDesktop
