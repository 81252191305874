import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

// components
import TopBar from './components/TopBar'
import NavBar from './components/NavBar'

//import { HeaderDataContent } from "@hooks/use-header-data";
import { HeaderDataContent } from './models'

let tmrDebounceScroll = null
const Header = styled(({ className }) => {
  const [didScroll, setDidScroll] = useState(false)
  const [scrollEnabler, setScrollEnabler] = useState(false)

  const handleScroll = (isDefining) => () => {
    clearTimeout(tmrDebounceScroll)
    if (!isDefining) return false
    tmrDebounceScroll = setTimeout(() => {
      if (window.scrollY >= 75) {
        setDidScroll(true)
      } else {
        setDidScroll(false)
      }
    }, 50)
  }

  const handleResize = (isDefining) => () => {
    if (!isDefining) return false
    if (window.matchMedia('(max-width: 991px)').matches) {
      setScrollEnabler(false)
    } else {
      setScrollEnabler(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll(true))
    window.addEventListener('resize', handleResize(true))
    return () => {
      window.removeEventListener('scroll', handleScroll(false))
      window.removeEventListener('resize', handleResize(false))
    }
  }, [scrollEnabler, didScroll])

  const topbar = HeaderDataContent()[0]
  const navbar = HeaderDataContent()[1]

  return (
    <div className={`${className} ${didScroll ? 'scrolled' : ''}`}>
      <TopBar data={topbar} />
      <header>
        <NavBar data={navbar} />
      </header>
    </div>
  )
})`
  position: fixed;
  width: 100%;
  z-index: 99;

  &.scrolled {
    .top-bar {
      margin-top: -40px;
    }

    @media (min-width: 1200px) {
      .navigation-content-top {
        .brand {
          height: 60px;

          .logo {
            display: none;
          }
          .favicon {
            display: block;
          }
        }

        .get-help {
          display: block;
          opacity: 1;
          visible: visible;
          width: unset;
        }
      }
    }
  }
`

export default Header
