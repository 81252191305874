import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { HamburgerElastic } from 'react-animated-burgers'

import colors from '@scripts/colors'

import ThemeContext from '@contexts/themeContext'

const MainIcon = styled(({ className }) => {
  const { activeMenu, toggleMenu } = useContext(ThemeContext)

  return (
    <HamburgerElastic
      onClick={() => toggleMenu(!activeMenu)}
      isActive={activeMenu}
      barColor={colors.brand}
      className={`${className} p-0 position-relative`}
    />
  )
})`
  @media (min-width: 768px) {
    height: 40px;
  }

  > div {
    display: block;
  }

  span {
    border-radius: 0;
    top: 0;

    &:before,
    &:after {
      border-radius: 0;
    }
  }

  &:focus {
    outline: 0;
  }
`

export default MainIcon
