import React from 'react'
import styled from '@emotion/styled'
import { object } from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'

import email from '@images/icons/email.svg'
import phone from '@images/icons/phone.svg'

const TopBar = styled(({ data, className }) => {
  return (
    <div className={`${className} top-bar`}>
      <Container>
        <Row className="align-items-center">
          <Col md="12" xl="6">
            <div className="topbar-text">{data.text}</div>
          </Col>
          <Col className="d-xl-block d-none">
            <ul>
              <li>
                <a href={`tel:${data.phone.link}`}>
                  <img src={phone} alt="phone"/>
                  {data.phone.text}
                </a>
              </li>
              <li>
                <a href={`mailto:${data.email}`}>
                  <img src={email} alt="email"/>
                  {data.email}
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  )
})`
  background-color: #231e60;
  color: #fff;
  padding: 8px 0;
  transition: all 0.2s ease-out;

  .topbar-text {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    @media (min-width: 1200px) {
      text-align: left;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0;

    li {
      list-style-type: none;
      margin-right: 48px;

      &:last-child {
        margin: 0;
      }

      a {
        color: #fff;
        display: flex;
        font-family: Titillium Web;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;

        img {
          margin-right: 16px;
        }
      }
    }
  }
`

TopBar.propTypes = {
  data: object.isRequired
}

TopBar.defaultProps = {
  data: {}
}

export default TopBar
